<template>
  <div>
    <!-- SN table -->
    <site-name-tables v-if="store.mmData" :toggleCreateSN="toggleCreateSN" :toggleEditSN="toggleEditSN" :setEditValue="setEditValue" ></site-name-tables>

    <!-- create SN modal -->
    <modal :show.sync="modals.createSN"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Create Site</h3>
      <create-site-name-forms class="mm-modal mm-create" :toggleCreateSN="toggleCreateSN" ></create-site-name-forms>
    </modal>

    <!-- edit SN modal -->
    <modal :show.sync="modals.editSN"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Edit Site</h3>
      <edit-site-name-forms class="mm-modal mm-create" :toggleEditSN="toggleEditSN" :editValue="editValue" ></edit-site-name-forms>
    </modal>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import SiteNameTables from 'src/components/Dashboard/Views/Tables/SiteNameTables.vue'
  import CreateSiteNameForms from 'src/components/Dashboard/Views/Forms/CreateSiteNameForms.vue'
  import EditSiteNameForms from 'src/components/Dashboard/Views/Forms/EditSiteNameForms.vue'

  export default {
    components: {
      SiteNameTables,
      CreateSiteNameForms,
      EditSiteNameForms,
      Modal,
      [Button.name]: Button,
    },
    data () {
      return {
        store,
        modals: {
          createSN: false,
          editSN: false,
        },
        editValue: {
          site: '',
          site_clean: '',
          root_domain: '',
          be_id: 1,
          vertical_id: 1,
          lang_id: 1,
          country_id: 232,
          status_level: 1,
        },
      }
    },
    methods: {
      setEditValue(obj) {
        const keyArr = Object.keys(obj),
        keyLen = keyArr.length;

        for (let k = 0; k < keyLen; k++) {
          const tmpKey = keyArr[k];

          this.editValue[tmpKey] = obj[tmpKey]
        }
      },
      toggleCreateSN() {
        this.modals.createSN = !this.modals.createSN
      },
      toggleEditSN() {
        this.modals.editSN = !this.modals.editSN
      },
    },
    async mounted () {
      //await store.initMundial()

      //console.log({store})
    }
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
</style>

<template>
    <div>
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(editCleanSiteMap)" autocomplete="off">

        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              name="cname"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'Clean Name must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="cname"
                      label="Clean Name"
                      placeholder="Site Name"
                      v-model="editValue.cname">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider
              name="sn_id"
              rules="required|min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input
                autocomplete="off"
                :error="failed ? 'The Site field is required': null"
                :hasSuccess="passed"
                name="sn_id"
                label="Site (required)"
                v-model="editValue.sn_id"
              >




                <!-- OG
                <div class="clearfix"></div>
                <select v-model="editValue.sn_id">
                  <option v-for="option in store.mmData.site_options" :value="option.value" :key="option.label">
                    {{ option.label }}
                  </option>
                </select>
                END -->



                <el-select class="select-primary"
                           size="large"
                           v-model="editValue.sn_id"
                           placeholder="Required *">
                      <el-option v-for="option in store.mmData.site_options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>



              </fg-input>
            </ValidationProvider>
          </div>
        </div>


        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd">
            Edit
          </button>
          <button type="button" class="btn btn-default btn-fill btn-wd" @click="toggleEditModal">
            Cancel
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  export default {
    props: ['toggleEditCleanSiteMap','editValue'],
    data () {
      return {
        store,
      }
    },
    methods: {
      toggleEditModal() {
        this.editValue.cname = ''
        this.editValue.sn_id = ''
        this.toggleEditCleanSiteMap()
      },
      async editCleanSiteMap () {
        const userDetails = JSON.parse(JSON.stringify(this.editValue)),
        tmpDateTime = (new Date()).toISOString().split('T'),
        tmpDate = tmpDateTime[0],
        tmpTime = tmpDateTime[1].split('.')[0],
        cleanDateTime = [tmpDate, tmpTime].join(' ');
        userDetails.udatetime = cleanDateTime
        userDetails.auto_map = 1

        console.log('Your data: ' + JSON.stringify(userDetails))

        const boolCheck = await store.updateMundialCleanSiteMap(userDetails)
        if (boolCheck === 'EXISTING_CLEAN') {
            alert('CLEAN NAME already exists!')
        } else if (boolCheck) {
            this.toggleEditCleanSiteMap()
        } else {
            alert('Clean Name is incorrect!')
        }
      },
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
    },
  }

</script>
<style>

</style>

<template>
    <div>
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createSN)" autocomplete="off">

        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              name="snname"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'Site Name must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="snname"
                      label="Name"
                      placeholder="Site Name"
                      v-model="details.site">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              name="company"
              rules="required|min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input
                autocomplete="off"
                :error="failed ? 'Billing Entity field is required': null"
                :hasSuccess="passed"
                name="company"
                label="Billing Entity"
                v-model="details.be_id"
              >



                <el-select class="select-primary"
                           size="large"
                           v-model="details.be_id"
                           placeholder="Required *">
                      <el-option v-for="option in store.mmData.be_options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>



              </fg-input>
            </ValidationProvider>
          </div>

          <div class="col-md-6">
            <ValidationProvider
              name="vertical"
              rules="required|min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input
                autocomplete="off"
                :error="failed ? 'Demographic field is required': null"
                :hasSuccess="passed"
                name="vertical"
                label="Demographic"
                v-model="details.vertical_id"
              >



                <el-select class="select-primary"
                           size="large"
                           v-model="details.vertical_id"
                           placeholder="Required *">
                      <el-option v-for="option in store.mmData.demo_options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>



              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              name="language"
              rules="required|min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input
                autocomplete="off"
                :error="failed ? 'Language field is required': null"
                :hasSuccess="passed"
                name="language"
                label="Language"
                v-model="details.lang_id"
              >



                <!-- OG
                <div class="clearfix"></div>
                <select v-model="editValue.lang_id">
                  <option v-for="option in store.mmData.lang_options" :value="option.value" :key="option.label">
                    {{ option.label }}
                  </option>
                </select>
                END -->



                <el-select class="select-primary"
                           size="large"
                           v-model="details.lang_id"
                           placeholder="Required *">
                      <el-option v-for="option in store.mmData.lang_options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>



              </fg-input>
            </ValidationProvider>
          </div>

          <div class="col-md-6">
            <ValidationProvider
              name="country"
              rules="required|min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input
                autocomplete="off"
                :error="failed ? 'Country field is required': null"
                :hasSuccess="passed"
                name="country"
                label="Country"
                v-model="details.country_id"
              >



                <!-- OG
                <div class="clearfix"></div>
                <select v-model="editValue.country_id">
                  <option v-for="option in store.mmData.country_options" :value="option.value" :key="option.label">
                    {{ option.label }}
                  </option>
                </select>
                END -->



                <el-select class="select-primary "
                           size="large"
                           v-model="details.country_id"
                           placeholder="Required *">
                      <el-option v-for="option in store.mmData.country_options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>



              </fg-input>
            </ValidationProvider>
          </div>
        </div>


        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd">
            Create
          </button>
          <button type="button" class="btn btn-default btn-fill btn-wd" @click="toggleCreateModal">
            Cancel
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  export default {
    props: ['toggleCreateSN'],
    data () {
      return {
        store,
        details: {
          site: '',
          site_clean: '',
          root_domain: '',
          be_id: 1,
          vertical_id: 1,
          lang_id: 1,
          country_id: 232,
          status_level: 1,
        }
      }
    },
    methods: {
      toggleCreateModal() {
        this.details = {
          site: '',
          site_clean: '',
          root_domain: '',
          be_id: 1,
          vertical_id: 1,
          lang_id: 1,
          country_id: 232,
          status_level: 1,
        }

        this.toggleCreateSN()
      },
      async createSN () {
        const userDetails = this.details;
        userDetails['site_clean'] = userDetails['site'].toLowerCase().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/gi, '')
        userDetails['root_domain'] = `https://${userDetails['site'].toLowerCase()}`

        console.log('Your data: ' + JSON.stringify(userDetails))

        const boolCheck = await this.store.createMundialSN(userDetails)
        if (boolCheck === 'EXISTING_SN') {
            alert('SITE NAME already exists!')
        } else if (boolCheck) {
            //this.$router.push({'name':'Overview'})      
            //alert('Site Name created successfully!')
            this.toggleCreateSN()
        } else {
            alert('SITE NAME is incorrect!')
        }
      },
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
    },
  }

</script>
<style>

</style>

<template>
    <div>
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createUser)" autocomplete="off">

        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              name="company"
              rules="required|min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input
                autocomplete="off"
                :error="failed ? 'The Company field is required': null"
                :hasSuccess="passed"
                name="company"
                label="Company (required)"
                v-model="details.mundialprofile"
              >
                <el-select class="select-primary"
                           size="large"
                           v-model="details.mundialprofile"
                           placeholder="Required *">
                      <el-option v-for="option in store.mmData.be_options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>
                <!-- OG


                <div class="clearfix"></div>
                <select v-model="details.mundialprofile">
                  <option v-for="option in store.mmData.be_options" :value="option.value" :key="option.label">
                    {{ option.label }}
                  </option>
                </select>


                END -->
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="col-md-6">
            <ValidationProvider
              name="email"
              rules="required|email|min:5"
              v-slot="{ passed, failed }"
            >
              <fg-input type="email"
                      autocomplete="off"
                      :error="failed ? 'The Email field is required': null"
                      :hasSuccess="passed"
                      name="email"
                      label="Email (required)"
                      placeholder="Email"
                      v-model="details.email">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              name="firstname"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'First Name must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="firstname"
                      label="First Name"
                      placeholder="First Name"
                      v-model="details.first_name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider
              name="lastname"
              rules="min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'Last Name must be at least 1 character': null"
                      :hasSuccess="passed"
                      name="lastname"
                      label="Last Name"
                      placeholder="Last Name"
                      v-model="details.last_name">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd">
            Create
          </button>
          <button type="button" class="btn btn-default btn-fill btn-wd" @click="toggleCreateModal">
            Cancel
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  export default {
    props: ['toggleCreateUser',],
    data () {
      return {
        store,
        details: {
          mundialprofile: '',
          username: '',
          email: '',
          first_name: '',
          last_name: '',
        },
      }
    },
    methods: {
      toggleCreateModal() {
        this.details.mundialprofile = ''
        this.details.username = ''
        this.details.email = ''
        this.details.first_name = ''
        this.details.last_name = ''

        this.toggleCreateUser()
      },
      async createUser () {
        const userDetails = this.details;
        userDetails.username = userDetails.email

        //console.log('Your data: ' + JSON.stringify(userDetails))

        const boolCheck = await this.store.createMundial(userDetails)
        if (boolCheck === 'EXISTING_USERNAME') {
            alert('USER already exists!')
        } else if (boolCheck) {
            this.toggleCreateUser()
        } else {
            alert('USER is incorrect!')
        }
      },
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
    },
  }

</script>
<style>

</style>

<template>
    <div>
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(editBE)" autocomplete="off">

        <div class="row">
          <div class="col-md-2">
          </div>
          <div class="col-md-8">
            <ValidationProvider
              name="bename"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'Billing Entity name must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="bename"
                      label="Name"
                      placeholder="BE Name"
                      v-model="editValue.be_name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-md-2">
          </div>
        </div>


        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd">
            Edit
          </button>
          <button type="button" class="btn btn-default btn-fill btn-wd" @click="toggleEditModal">
            Cancel
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  export default {
    props: ['toggleEditBE','editValue'],
    data () {
      return {
        store,
      }
    },
    computed: {
      tableColumns () {
        return store.tableColumns
      },
    },
    methods: {
      toggleEditModal() {
        this.editValue.be_name = ''
        this.toggleEditBE()
      },
      async editBE () {
        const userDetails = {};
        userDetails.be_name = this.editValue.be_name
        userDetails.id = this.editValue.id

        //console.log('Your data: ' + JSON.stringify(userDetails))

        const boolCheck = await store.updateMundialBE(userDetails)
        if (boolCheck === 'EXISTING_BE') {
            alert('BILLING ENTITY already exists!')
        } else if (boolCheck) {
            //this.$router.push({'name':'Overview'})      
            //alert('Billing Entity updated successfully!')
            this.toggleEditBE()
        } else {
            alert('BILLING ENTITY is incorrect!')
        }
      },
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
    },
  }

</script>
<style>

</style>

<template>
  <div>
    <!-- Ignored AU table -->
    <ignored-ad-unit-tables v-if="store.mmData" :toggleCreateIgnored="toggleCreateIgnored" :toggleEditIgnored="toggleEditIgnored" :setEditValue="setEditValue" ></ignored-ad-unit-tables>

    <!-- create ignored modal -->
    <modal :show.sync="modals.createIgnored"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Create Ignored Ad Unit</h3>
      <create-ignored-forms class="mm-modal mm-create" :toggleCreateIgnored="toggleCreateIgnored" ></create-ignored-forms>
    </modal>

    <!-- edit ignored modal -->
    <modal :show.sync="modals.editIgnored"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Edit Ignored Ad Unit</h3>
      <edit-ignored-forms class="mm-modal mm-create" :toggleEditIgnored="toggleEditIgnored" :editValue="editValue" ></edit-ignored-forms>
    </modal>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import IgnoredAdUnitTables from 'src/components/Dashboard/Views/Tables/IgnoredAdUnitTables.vue'
  import CreateIgnoredForms from 'src/components/Dashboard/Views/Forms/CreateIgnoredForms.vue'
  import EditIgnoredForms from 'src/components/Dashboard/Views/Forms/EditIgnoredForms.vue'

  export default {
    components: {
      IgnoredAdUnitTables,
      CreateIgnoredForms,
      EditIgnoredForms,
      Modal,
      [Button.name]: Button,
    },
    data () {
      return {
        store,
        modals: {
          createIgnored: false,
          editIgnored: false,
        },
        editValue: {
          adunit: '',
        },
      }
    },
    methods: {
      setEditValue(obj) {
        const keyArr = Object.keys(obj),
        keyLen = keyArr.length;

        for (let k = 0; k < keyLen; k++) {
          const tmpKey = keyArr[k];

          this.editValue[tmpKey] = obj[tmpKey]
        }
      },
      toggleCreateIgnored() {
        this.modals.createIgnored = !this.modals.createIgnored
      },
      toggleEditIgnored() {
        this.modals.editIgnored = !this.modals.editIgnored
      },
    },
    async mounted () {
      //await store.initMundial()

      //console.log({store})
    }
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
</style>

<template>
  <div>
    <ValidationObserver v-slot="{ invalid }" ref="observer" tag="form" @submit.prevent="getCostMaps()">
    <div v-if="store.mmData" class="row mundialBorders mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="start_date"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Start Date (Required *)"
                name="start_date" 
                autocomplete="off"
                v-model="store.mmDateRange[0]"
                :error="failed ? 'Start Date field is required': null"
                :hasSuccess="passed"
            >
              <el-date-picker v-model="store.mmDateRange[0]" type="date"
                          class="date-mundial"
                          :picker-options="pickerOptions">
              </el-date-picker>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <fg-input label="End Date">
            <el-date-picker v-model="store.mmDateRange[1]" type="date"
                          class="date-mundial"
                          :picker-options="pickerOptions">
            </el-date-picker>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-4 mundial-vcenter">
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-2 mundial-costmap-vcenter">
        <button @click.prevent="getCostMaps()" class="btn btn-success btn-fill btn-wd btn-mundial-success" :disabled="invalid">Retrieve</button>
      </div>
    </div>

    <div v-if="store.mmData" class="row mundialBorders mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="company"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Billing Entity" v-if="store.mmData"
                name="company"
                autocomplete="off"
                v-model="store.companySelected"
                :error="failed ? 'The Company field is required': null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default"
                       size="large"
                       v-model="store.companySelected"
                       placeholder="Required *">
                  <el-option v-for="option in store.mmData.be_options"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <fg-input label="Site" v-if="store.mmData">
            <el-select class="select-default mundial-default"
                       size="large"
                       v-model="store.siteSelected"
                       placeholder="Required *">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(siteOptions)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-4 mundial-vcenter">
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-2 mundial-vcenter">
        <button @click.prevent="resetFilters()" class="btn btn-default btn-fill btn-wd btn-mundial-reset">Reset</button>
      </div>
    </div>


    <div v-if="store.mmData" class="row mundialBorders mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="device"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Device" v-if="store.mmData"
                name="device"
                autocomplete="off"
                v-model="store.deviceSelected"
                :error="failed ? 'The Device field is required': null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default"
                       size="large"
                       v-model="store.deviceSelected"
                       placeholder="Required *">
                  <el-option v-for="option in store.mmData.device_options"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="product"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Product" v-if="store.mmData"
                name="product"
                autocomplete="off"
                v-model="store.productSelected"
                :error="failed ? 'The Product field is required': null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default"
                       size="large"
                       v-model="store.productSelected"
                       placeholder="Required *">
                  <el-option v-for="option in store.mmData.product_options"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <fg-input label="Sub-Product" v-if="store.mmData">
            <el-select class="select-default mundial-default"
                       size="large"
                       v-model="store.subProductSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(subProductOptions)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="inter"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Inter" v-if="store.mmData"
                name="inter"
                autocomplete="off"
                v-model="store.interSelected"
                :error="failed ? 'Region field is required': null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default"
                       size="large"
                       v-model="store.interSelected"
                       placeholder="Required *">
                  <el-option v-for="option in [{value:'usa',label:'USA Only'},{value:'inter',label:'Inter'},{value:'all',label:'Global'}]"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
    </div>
    </ValidationObserver>

    <!-- mapped cost maps table -->
    <manage-cost-maps-tables :costMapFlag="costMapFlag" :costMapList="costMapList" :ogCostMapList="ogCostMapList" :resetFilters="resetFilters"></manage-cost-maps-tables>
  </div>
</template>
<script>
  import { DatePicker } from 'element-ui'
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import ManageCostMapsTables from 'src/components/Dashboard/Views/Tables/ManageCostMapsTables.vue'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);  

  // Filter variables
  const mmDate = store.mmDate(),
  maxDate = store.mmDate(),
  maxDateThresh = store.mmDate(),
  minDateThresh = new Date(store.mmData.active_month),
  storeNameDict = {
    'date': 'mmDateRange',
    'product_type': 'productSelected',
    'product_subtype': 'subProductSelected',
    'billing_entity': 'companySelected',
    'site_name': 'siteSelected',
  },
  lastSeven = mmDate.GetLastSeven(),
  sDate = lastSeven['startDay'].toISOString().split('T')[0],
  eDate = lastSeven['endDay'].toISOString().split('T')[0];

  //
  // Set Dashboard Defaults
  //
  //store.mmDateRange = [sDate, eDate]
  store.companySelected = ''
  store.deviceSelected = ''
  store.productSelected = ''
  store.interSelected = 'usa'

  // Default daterange thresholds
  //maxDateThresh.setTime(maxDateThresh.getTime() - 3600 * 1000 * 24);
  maxDateThresh.setTime(maxDateThresh.getTime() + 3600 * 1000 * 24 * 45);

  console.log({minDateThresh,maxDateThresh})

  export default {
    components: {
      ManageCostMapsTables,
      Modal,
      [Button.name]: Button,
      [DatePicker.name]: DatePicker,
    },
    data () {
      return {
        costMapList: [],
        ogCostMapList: [],
        store,
        costMapFlag: false,
        pickerOptions: {
          disabledDate (date) {
            let boolCheck = false;
            if (date > maxDateThresh) boolCheck = true
            if (date < minDateThresh) boolCheck = true
            
            //return date > maxDateThresh || date < minDateThresh
            return boolCheck
          }
        }
      }
    },
    computed: {
      startDate () {
        return store.mmDateRange.length ? store.mmDateRange[0] : ''
      },
      endDate () {
        return store.mmDateRange.length ? store.mmDateRange[1] : ''
      },
      siteOptions () {
        const companySelected = store.companySelected === 'all' ? false : store.mmData.be_options.filter(obj => obj.value === store.companySelected)[0],
        be_id = companySelected ? companySelected.value : false,
        be_sn_set = be_id ? new Set(store.mmData.be_sn_dict.filter(obj => obj.be_id === be_id)[0].sn_id.split(',')) : false;

        console.log({companySelected, be_id, be_sn_set})

        //if (be_sn_set) {
          //store.siteSelected = 'all'
          //this.setSummaryData()
        //}

        return be_sn_set ? store.mmData.site_options.filter(obj => be_sn_set.has(obj.value.toString())) : store.mmData.site_options
      },
      subProductOptions () {
        const productSelected = store.productSelected === 'all' ? false : store.mmData.product_options.filter(obj => obj.value === store.productSelected)[0],
        product_id = productSelected ? productSelected.value : false,
        subproduct_list = store.mmData.product_subproduct_dict.filter(obj => obj.product_type_id === product_id),
        product_subproduct_set = product_id && subproduct_list.length ? new Set(subproduct_list[0].product_subtype_id.split(',')) : false;

        //if (product_subproduct_set) {
          //store.subProductSelected = 'all'
          //this.setSummaryData()
        //}

        console.log({productSelected, product_id, subproduct_list, product_subproduct_set})

        return product_subproduct_set ? store.mmData.subproduct_options.filter(obj => product_subproduct_set.has(obj.value.toString())) : product_id && !subproduct_list.length ? [] : store.mmData.subproduct_options
      },
    },
    methods: {
      resetFilters () {
        // Update global
        store.mmDateRange = []
        store.companySelected = ''
        store.siteSelected = 'all'
        store.deviceSelected = ''
        store.productSelected = ''
        store.subProductSelected = 'all'
        store.interSelected = 'usa'
        store.mmData.manage_sncostmap_list = false

        // Update local
        this.costMapFlag = false;
      },
      handleMoney(row, prop) {
        const tmpVal = row[prop],
        newVal = !isNaN(parseInt(tmpVal)) ? new Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD'}).format(tmpVal) : '';

        //console.log({newVal})

        return newVal == 'NaN' ? '' : newVal
      },      
      handleNumbers(row, prop) {
        const tmpVal = row[prop],
        newVal = !isNaN(parseInt(tmpVal)) ? Number(tmpVal).toLocaleString("en-US") : '';

        //console.log({newVal})

        return newVal == 'NaN' ? '' : newVal
      },
      stringifyRows (dataRows) {
        const cleanRows = [], tableRows = JSON.parse(JSON.stringify(dataRows)), 
        tableLen = tableRows.length,
        handleMoney = this.handleMoney, handleNumbers = this.handleNumbers;

        for (let r = 0; r < tableLen; r++) {
          const tmpRow = tableRows[r];

          // Convert to strings
          tmpRow.cpm_cost = handleMoney(tmpRow, 'cpm_cost')
          tmpRow.mg_daily_imp = handleNumbers(tmpRow, 'mg_daily_imp')
          tmpRow.mg_monthly_imp = handleNumbers(tmpRow, 'mg_monthly_imp')
          tmpRow.mc_daily_adreq = handleNumbers(tmpRow, 'mc_daily_adreq')
          tmpRow.mc_monthly_adreq = handleNumbers(tmpRow, 'mc_monthly_adreq')

          cleanRows.push(tmpRow)
        }

        return cleanRows
      },
      async getCostMaps () {
        this.costMapFlag = false;

        //console.log({store})

        // Mapping variables
        const dateRange = [store.mmDateRange[0], store.mmDateRange[1]],
        billingEntity = store.companySelected,
        siteName = store.siteSelected,
        deviceType = store.deviceSelected,
        productType = store.productSelected,
        productSubtype = store.subProductSelected,
        inter = store.interSelected,
        tmpMin = new Date(dateRange[0]),
        tmpMax = dateRange[1] ? new Date(dateRange[1]) : false,
        adjMin = new Date(tmpMin.getTime()).toISOString().split('T')[0],
        adjMax = tmpMax ? new Date(tmpMax.getTime()).toISOString().split('T')[0] : false,
        //adjMin = new Date(tmpMin.getTime() - 3600 * 1000 * 24 * 1).toISOString().split('T')[0],
        //adjMax = tmpMax ? new Date(tmpMax.getTime() - 3600 * 1000 * 24 * 1).toISOString().split('T')[0] : false,
        //queryMin = new Date(tmpMin.getTime() - 3600 * 1000 * 24 * 2).toISOString().split('T')[0],
        queryMin = new Date(tmpMin.getTime() - 3600 * 1000 * 24 * 1).toISOString().split('T')[0],
        queryMax = tmpMax ? new Date(tmpMax.getTime() + 3600 * 1000 * 24 * 1).toISOString().split('T')[0] : false,
        queryDateRange = queryMax ? [queryMin, queryMax] : [queryMin];

        // Adjust dates
        //dateRange[0] = tmpMin;
        //dateRange[1] = tmpMax ? tmpMax : tmpMin;
        dateRange[0] = adjMin;
        dateRange[1] = tmpMax ? adjMax : adjMin;
        if (adjMax) dateRange[1] = adjMax;

        //console.log({store,sDate:store.mmDateRange[0].toISOString().split('T')[0],eDate:store.mmDateRange[1].toISOString().split('T')[0]})
        //console.log({dateRange, productType, productSubtype, billingEntity, siteName, queryDateRange})

        // Retrieve DB data & exclude unmapped entries (sncostmap_list)
        //const snCostMaps = [{id:678},{id:679}],
        const snCostMaps = store.mmData.sncostmap_list,
        boolCheck = await store.getMundialSnCostMap({dateRange, productType, productSubtype, billingEntity, siteName, queryDateRange, inter, deviceType, snCostMaps});
        if (boolCheck) {
          this.costMapList = await this.stringifyRows(store.mmData.manage_sncostmap_list)
          this.ogCostMapList = JSON.parse(JSON.stringify(this.costMapList))
        }

        // Update render flag
        this.costMapFlag = true;
      }
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
    },
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
  .date-mundial {
    border-radius: 12px !important;
    border: 1px solid #ddd !important;
  }
  .date-mundial > .el-input__inner {
    border-radius: 10px !important;
  }
  .mundial-costmap-vcenter > .btn-mundial-success {
    float: right;
  }
</style>

<template>
  <div>
    <!-- Clean Site Map table -->
    <clean-site-map-tables v-if="store.mmData" :toggleCreateCleanSiteMap="toggleCreateCleanSiteMap" :toggleEditCleanSiteMap="toggleEditCleanSiteMap" :setEditValue="setEditValue" ></clean-site-map-tables>

    <!-- create clean site map modal -->
    <modal :show.sync="modals.createCleanSiteMap"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Create Clean Site Map</h3>
      <create-clean-site-map-forms class="mm-modal mm-create" :toggleCreateCleanSiteMap="toggleCreateCleanSiteMap" ></create-clean-site-map-forms>
    </modal>

    <!-- edit clean site map modal -->
    <modal :show.sync="modals.editCleanSiteMap"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Edit Clean Site Map</h3>
      <edit-clean-site-map-forms class="mm-modal mm-create" :toggleEditCleanSiteMap="toggleEditCleanSiteMap" :editValue="editValue" ></edit-clean-site-map-forms>
    </modal>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import CleanSiteMapTables from 'src/components/Dashboard/Views/Tables/CleanSiteMapTables.vue'
  import CreateCleanSiteMapForms from 'src/components/Dashboard/Views/Forms/CreateCleanSiteMapForms.vue'
  import EditCleanSiteMapForms from 'src/components/Dashboard/Views/Forms/EditCleanSiteMapForms.vue'

  export default {
    components: {
      CleanSiteMapTables,
      CreateCleanSiteMapForms,
      EditCleanSiteMapForms,
      Modal,
      [Button.name]: Button,
    },
    data () {
      return {
        store,
        modals: {
          createCleanSiteMap: false,
          editCleanSiteMap: false,
        },
        editValue: {
          cname: '',
          sn_id: '',
        },
      }
    },
    methods: {
      setEditValue(obj) {
        const keyArr = Object.keys(obj),
        keyLen = keyArr.length;

        for (let k = 0; k < keyLen; k++) {
          const tmpKey = keyArr[k];

          this.editValue[tmpKey] = obj[tmpKey]
        }
      },
      toggleCreateCleanSiteMap() {
        this.modals.createCleanSiteMap = !this.modals.createCleanSiteMap
      },
      toggleEditCleanSiteMap() {
        this.modals.editCleanSiteMap = !this.modals.editCleanSiteMap
      },
    },
    async mounted () {
      //await store.initMundial()

      //console.log({store})
    }
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
</style>

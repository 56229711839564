<template>
  <div v-if="!store.mmData.user.display_tc" class="row">
    <div class="col-md-12">
      <h4 class="title">Clean Site Map List</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">
          utilities table
          <button id="exportMM" class="btn btn-badge btn-round btn-warning pull-right" @click="toggleCreateCleanSiteMap">
            create new <i class="nc-icon nc-simple-add"></i>
          </button>          
        </div>
      </div>
      <div class="card-body row body-mm">
        <div class="col-sm-2">
          <div class="form-group">
            <fg-input label="Rows" v-if="store.mmData">
              <el-select
                class="select-default mm-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="col-sm-8">
          <!-- empty -->
        </div>
        <div class="col-sm-2">
          <div class="pull-right">
            <fg-input class="input-sm search-mm"
                      placeholder="Search"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped"
                    :data="queriedData"
                    border
                    style="width: 100%">
            <el-table-column v-for="column in tableColumns"
                             sortable
                             :align="column.align"
                             :key="column.label"
                             :min-width="store.colWidthDict[column.prop]"
                             :prop="column.prop"
                             :label="column.label">
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              min-width="160"
              label="Actions">
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import { store } from 'src/store'
  import { cleanSiteCols, cleanSiteSearch } from './MundialColumns'
  import users from './users'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default{
    props: ['toggleCreateCleanSiteMap','toggleEditCleanSiteMap','setEditValue'],
    components: {
      PPagination
    },
    methods: {
      handleEdit (index, row) {
        const tmpRow = store.mmData.cname_list.find(cname => cname.id === row.id);

        console.log({index, row, tmpRow})
 
        this.setEditValue(tmpRow)
        this.toggleEditCleanSiteMap()
      },
      handleDelete (index, row) {
        console.log({index, row})
        if (confirm(`Are you sure you want to remove ${row.cname}? This cannot be undone.`)) store.deleteMundialCleanSiteMap({id: row.id})
      },
    },
    computed: {
      tableColumns () {
        //console.log({cleanSiteCols})
        return cleanSiteCols
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        const propsToSearch = cleanSiteSearch,
        lowerSearchQuery = this.searchQuery.toLowerCase()
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of propsToSearch) {
              let rowValue = row[key].toString().toLowerCase()
              if (rowValue.includes && rowValue.includes(lowerSearchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      generateSummary () {
        const mundialData = JSON.parse(JSON.stringify(store.mmData.cname_list)),
        metricArr = ['sn_id'],
        metricSet = new Set(metricArr);

        return store.sanitizeMetrics(mundialData, metricSet, false)
      },
      tableData () {
        // Toggle Aggregation
        let cleanRows = [];
        if (store.mmData) {
          //cleanRows = store.mmData.cname_list
          cleanRows = this.generateSummary
        }
        console.log(store.mmData.cname_list)
        console.log({cleanRows})
        return cleanRows;
      },
    },
    data () {
      return {
        store,
        MUNDIAL_PLATFORM,
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100, 200],
          total: 0
        },
        searchQuery: '',
      }
    },
    mounted () {
      // Generate naming dictionary
      const tableColumns = cleanSiteCols;
      for (let c = 0; c < tableColumns.length; c++) {
        const tmpCol = tableColumns[c];
        store.colsDict[tmpCol.prop] = tmpCol.label
      }

      // Log to console
      console.log({store})
    },
  }
</script>
<style>
  .mmWarning {
    color: #ef8157;
  }
  #exportMM {
    color: black;
  }
  .search-mm {
    margin-top: 25px;
  }
  .body-mm {
    padding-top: 0px !important;
  }
</style>
<style lang="scss">
  .el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
  }
</style>

<template>
  <div>
    <!-- Iris User table -->
    <iris-user-tables v-if="store.mmData" :toggleCreateIrisUser="toggleCreateIrisUser" :toggleEditIrisUser="toggleEditIrisUser" :setEditValue="setEditValue" ></iris-user-tables>

    <!-- create Iris User modal -->
    <modal :show.sync="modals.createIrisUser"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Create Iris User</h3>
      <create-iris-user-forms class="mm-modal mm-create" :toggleCreateIrisUser="toggleCreateIrisUser" ></create-iris-user-forms>
    </modal>

    <!-- edit Iris User modal -->
    <modal :show.sync="modals.editIrisUser"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Edit Iris User</h3>
      <edit-iris-user-forms class="mm-modal mm-create" :toggleEditIrisUser="toggleEditIrisUser" :editValue="editValue" ></edit-iris-user-forms>
    </modal>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import IrisUserTables from 'src/components/Dashboard/Views/Tables/IrisUserTables.vue'
  import CreateIrisUserForms from 'src/components/Dashboard/Views/Forms/CreateIrisUserForms.vue'
  import EditIrisUserForms from 'src/components/Dashboard/Views/Forms/EditIrisUserForms.vue'

  export default {
    components: {
      IrisUserTables,
      CreateIrisUserForms,
      EditIrisUserForms,
      Modal,
      [Button.name]: Button,
    },
    data () {
      return {
        store,
        modals: {
          createIrisUser: false,
          editIrisUser: false,
        },
        editValue: {
          email: '',
          first_name: '',
          last_name: '',
          is_active: '',
        },
      }
    },
    methods: {
      setEditValue(obj) {
        const keyArr = Object.keys(obj),
        keyLen = keyArr.length;

        for (let k = 0; k < keyLen; k++) {
          const tmpKey = keyArr[k];

          this.editValue[tmpKey] = obj[tmpKey]
        }
      },
      toggleCreateIrisUser() {
        this.modals.createIrisUser = !this.modals.createIrisUser
      },
      toggleEditIrisUser() {
        this.modals.editIrisUser = !this.modals.editIrisUser
      },
    },
    async mounted () {
      //await store.initMundial()

      //console.log({store})
    }
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
</style>

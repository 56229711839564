<template>
  <div>
    <!-- Unmapped AU table -->
    <external-cost-map-tables v-if="store.mmData"></external-cost-map-tables>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import ExternalCostMapTables from 'src/components/Dashboard/Views/Tables/ExternalCostMapTables.vue'

  export default {
    components: {
      ExternalCostMapTables,
      Modal,
      [Button.name]: Button,
    },
    data () {
      return {
        store,
      }
    }
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
</style>

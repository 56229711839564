<template>
  <div>


    <!-- Filters -->

    <div v-if="store.mmData" class="row mundialBorders mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-4">
        <div class="form-group">
          <fg-input label="Date Range" v-if="store.mmData">
            <el-date-picker v-model="store.mmDateRange" type="daterange"
                          class="daterange-mundial"
                          @change="setSummaryData()"
                          :start-placeholder="startDate"
                          :end-placeholder="endDate"
                          :default-value="startDate"
                          :picker-options="pickerOptions1">
            </el-date-picker>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-2">
        <div v-if="store.mmData.user.is_staff" class="form-group">
          <fg-input label="Billing Entity" v-if="store.mmData">
            <el-select class="select-default mundial-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.companySelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all', label:'All'}].concat(store.mmData.be_options)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
        <div v-else class="form-group">
          <fg-input label="Site" v-if="store.mmData">
            <el-select multiple class="select-default mundial-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.siteSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(siteOptions)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-2">
        <div v-if="store.mmData.user.is_staff" class="form-group">
          <fg-input label="Site" v-if="store.mmData">
            <el-select multiple class="select-default mundial-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.siteSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(siteOptions)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-2">
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-2 mundial-vcenter">
        <!-- OG btn <a class="mundial-a" @click="resetMundialData()">Reset Filters</a> -->

        <button @click="resetMundialData()" class="btn btn-default btn-fill btn-wd btn-mundial-reset">Reset Filters</button>

      </div>
    </div>
    <div v-if="store.mmData" class="row mundialBorders mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-2">
        <div class="form-group">
          <fg-input label="Device" v-if="store.mmData">
            <el-select class="select-default mundial-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.deviceSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(store.mmData.device_options)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-2">
        <div class="form-group">
          <fg-input label="Product" v-if="store.mmData">
            <el-select multiple class="select-default mundial-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.productSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(store.mmData.product_options)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-2">
        <div class="form-group">
          <fg-input label="Sub-Product" v-if="store.mmData">
            <el-select multiple class="select-default mundial-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.subProductSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(subProductOptions)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-6">
      </div>
    </div>

    <!-- Exportable table -->
    <pre-iris-tables v-if="store.mmData && store.mmData.mundial" :setSummaryData="setSummaryData" :aggregateOptions="aggregateOptions"></pre-iris-tables>

</div>
</template>
<script>
  import { DatePicker } from 'element-ui'
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import { Badge } from 'src/components/UIComponents'
  import PreIrisTables from 'src/components/Dashboard/Views/Tables/PreIrisTables.vue'
  import { store } from 'src/store'

  const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
    callbacks: {
        label: function(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
                label += ': ';
            }
            label += tooltipItem.yLabel.toLocaleString("en-US");
            return label;
        }
    }
  }, mundialOptions = {
    tooltips: tooltipOptions,
    scales: {
           yAxes: [{
               ticks: {
                   padding : 5,
                   fontColor: "#9f9f9f",
                   // Include a dollar sign in the ticks
                   callback: function(value, index, values) {
                       return new Intl.NumberFormat("en-US", {
                         notation: "compact",
                         compactDisplay: "short",
                       }).format(value);
                   },
               },
               gridLines: {
                   display: true,
                   drawBorder: true,
                   drawOnChartArea: false               
               },
           }],
           xAxes: [{
               display: true,
               ticks: {
                   padding : 5,
                   fontColor: "#9f9f9f",
                   callback: function(value, index, values) {
                       const maxIdx = values.length - 1;
                       var tickSpread = Math.floor(maxIdx / 10);
                       tickSpread = tickSpread === 0 ? 1 : tickSpread;

                       return index % tickSpread === 0 ? value : '';

                   },
               },
               gridLines: {
                   display: true,
                   drawBorder: true,
                   drawOnChartArea: false
               },
           }],
       },
  }, mundialDataset = {
       title: 'Mundial Media',
       borderColor: '#6bd098',
       pointBackgroundColor: '#6bd098',
       pointRadius: 2,
       pointHoverRadius: 6,
       borderWidth: 3,
       label: 'Data Zero',
       lineTension: 0,
       fill: false,
       data: []
  }, revenueOptions = JSON.parse(JSON.stringify(mundialOptions)),
  mmDate = store.mmDate(),
  maxDate = store.mmDate(),
  maxDateThresh = store.mmDate(),
  minDateThresh = store.mmDate(),
  storeNameDict = {
    'date': 'mmDateRange',
    'product_type': 'productSelected',
    'device_type': 'deviceSelected',
    'product_subtype': 'subProductSelected',
    'billing_entity': 'companySelected',
    'site_name': 'siteSelected',
  };

  // Default Dates
  maxDateThresh.setTime(maxDateThresh.getTime() - 3600 * 1000 * 24);
  minDateThresh.setTime(minDateThresh.getTime() - 3600 * 1000 * 24 * 105);

  //
  // Update revenueOptions
  //
  revenueOptions.tooltips.callbacks.label = function(tooltipItem, data) {
      let label = data.datasets[tooltipItem.datasetIndex].label || '';

      if (label) {
          //label += ': $';
          label += ': ';
      }
      //label += tooltipItem.yLabel.toLocaleString("en-US");
      //label += tooltipItem.yLabel.toFixed(2);
      label += new Intl.NumberFormat("en-US",
        { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }
      ).format(tooltipItem.yLabel);

      return label;
  }
  revenueOptions.scales.yAxes[0].ticks.callback = function(value, index, values) {
      return '$' + new Intl.NumberFormat("en-US", {
          notation: "compact",
          compactDisplay: "short",
      }).format(value);
  }
  revenueOptions.scales.xAxes[0].ticks.callback = function(value, index, values) {
      const maxIdx = values.length - 1;
      var tickSpread = Math.floor(maxIdx / 10);
      tickSpread = tickSpread === 0 ? 1 : tickSpread;

      return index % tickSpread === 0 ? value : '';

  }

  export default {
    components: {
      StatsCard,
      ChartCard,
      Badge,
      MundialTables,
      [DatePicker.name]: DatePicker,
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        store,
        mundialOptions,
        revenueOptions,
        aggregateOptions: [{value:'total',label:'Total'},{value:'daily',label:'Daily'}],
        statsCards: [
          {
            type: 'info',
            icon: 'nc-icon nc-globe',
            title: 'Total Requests',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'warning',
            icon: 'nc-icon nc-globe',
            title: 'Requests',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'default',
            icon: 'nc-icon nc-globe',
            title: 'International Requests',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'info',
            icon: 'nc-icon nc-tv-2',
            title: 'Monetizable Requests',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-vector',
            title: 'Impressions',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'success',
            icon: 'nc-icon nc-money-coins',
            title: 'Revenue',
            value: '$',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          }
        ],
        matchedAdReqChart: {
          data: false
        },
        impChart: {
          data: false
        },
        revenueChart: {
          data: false
        },
        pickerOptions1: {
          disabledDate (date) {
            if (store.mmData && store.mmData.user.is_staff) {
              return date > maxDateThresh
            } else {
              let boolCheck = false;
              if (date > maxDateThresh) boolCheck = true
              if (date < minDateThresh) boolCheck = true

              return boolCheck
            }
          },
          shortcuts: [{
            text: 'MTD',
            onClick (picker) {
              const monthToDate = mmDate.GetMonthToDate(),
              sDate = monthToDate['startDay'],
              eDate = monthToDate['endDay'] > maxDate ? maxDate : monthToDate['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'MTD'
            }
          },
          {
            text: 'Last 7 Days',
            onClick (picker) {
              const lastSeven = mmDate.GetLastSeven(),
              sDate = lastSeven['startDay'],
              eDate = lastSeven['endDay'] > maxDate ? maxDate : lastSeven['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'Last 7 Days'
            }
          },
          {
            text: 'Last 30 Days',
            onClick (picker) {
              const lastThirty = mmDate.GetLastThirty(),
              sDate = lastThirty['startDay'],
              eDate = lastThirty['endDay'] > maxDate ? maxDate : lastThirty['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'Last 30 Days'
            }
          },
          {
            text: 'Last Week',
            onClick (picker) {
              const lastWeek = mmDate.GetLastWeek(),
              sDate = lastWeek['startDay'],
              eDate = lastWeek['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'Last Week'
            }
          },
          {
            text: 'Last Month',
            onClick (picker) {
              const lastMonth = mmDate.GetLastMonth(),
              sDate = lastMonth['startDay'],
              eDate = lastMonth['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'Last Month'
            }
          }]
        },
        filters: [],
        mmText: 'Last 30 Days',
      }
    },
    methods: {
      reformatDate (tmpDate) {
        //console.log({tmpDate})

        return tmpDate ? [tmpDate.split('-')[2], tmpDate.split('-')[0], tmpDate.split('-')[1]].join('-') : tmpDate
      },
      async setMinMaxMundial() {
        const mundial = store.mmData.mundial, minIdx = 0, maxIdx = mundial.length - 1,
        tmpMin = new Date(mundial.length ? mundial[minIdx].date : store.mmDateRange[0]),
        tmpMax = new Date(mundial.length ? mundial[maxIdx].date : store.mmDateRange[1]),
        minDate = tmpMin < tmpMax ? tmpMin : tmpMax, maxDate = tmpMax > tmpMin ? tmpMax : tmpMin,
        filters = this.filters, filterMin = new Date(filters[0][1][0]), filterMax = new Date(filters[0][1][1]),
        customBool = store.customMinMaxDate.length,
        customMin = customBool ? store.customMinMaxDate[0] : false,
        customMax = customBool ? store.customMinMaxDate[1] : false,
        rangeBool = filterMin > customMin && filterMax < customMax,
        queryBool = customBool ? !rangeBool : false,
        sessionBool = store.mmData.sessionFilters;

        // Initial Min-Max
        if (!store.mmMinMaxDate.length) {
          store.mmMinMaxDate = [ minDate, maxDate ]
        }

        // Check for custom dateRange ( customRange )
        if (!customBool && filterMin < minDate) {
          //console.log({customBool})
          const adjMin = new Date(filterMin.getTime() - 3600 * 1000 * 24);

          store.customMinMaxDate[dashName] = [ adjMin, filterMax ]
          store.customQuery[dashName] = true
          store.mmData.custom[dashName] = await store.customPreIris()
        }

        // Check if filterRange outside existing customRange
        if (queryBool && filterMin < minDate) {
          //console.log({queryBool})

          store.customMinMaxDate[dashName] = [ filterMin, filterMax ]
          store.customQuery[dashName] = true
          store.mmData.custom[dashName] = await store.customPreIris()
        }

        // Check if filterRange inside existing customRange
        if (customBool && rangeBool) {
          store.customQuery[dashName] = true
        }

        // Reset if within mmData.mundial
        if (filterMin > minDate) {
          store.customQuery[dashName] = false
        }

        // Adjust customRange outside initial mmRange
        if (queryBool && sessionBool) {
          store.mmData.adjust_custom[dashName] = true
        }

        //console.log({minIdx, maxIdx, minDate, maxDate, mundial, filterMin, filterMax, filters, mundialData:store.mundialData, customData:store.mmData.custom})
      },
      async setSummaryData() {
        store.mmLoader = true
        this.resetFilters()
        await this.setMinMaxMundial()

        //console.log({filters:this.filters,mundial:store.mmData.mundial})
        //console.log({store})

        if(this.filters.length){
          store.mundialData = (JSON.parse(JSON.stringify(store.filterMundial(this.filters)))).sort(function(x, y){
            const date1 = new Date(x.date),
            date2 = new Date(y.date);
            return date1 - date2;
          })

        } else {
          store.mundialData = (JSON.parse(JSON.stringify(store.customQuery ? store.mmData.custom : store.mmData.mundial))).sort(function(x, y){
            const date1 = new Date(x.date),
            date2 = new Date(y.date);
            return date1 - date2;
          })

        }

        //console.log({mundialData:store.mundialData})

        this.mundialSessionFilters()
        store.mmLoader = false
      },
      mundialSessionFilters() {
        const sessionFilters = {},
        mundialFilters = this.filters,
        sessionBool = store.mmData.sessionFilters,
        sessMin = sessionBool ? sessionBool.filters[0][1][0] !== mundialFilters[0][1][0] : false,
        sessMax = sessionBool ? sessionBool.filters[0][1][1] !== mundialFilters[0][1][1] : false,
        newRange = sessMin && sessMax,
        customBool = sessionBool ? newRange : true;

        //console.log({sessionBool,mundialFilters})

        // Session filters (Django)
        sessionFilters['filters'] = mundialFilters
        sessionFilters['mmText'] = store.mmText
        sessionFilters['aggregateSelected'] = store.aggregateSelected

        // Modify if customRange (outside mmRange)
        if (store.mmText === 'Custom Range' && customBool) {
          const customQuery = store.customQuery ? 0 : 1, tmpMin = new Date(sessionFilters['filters'][0][1][0]), strMin = new Date(tmpMin.getTime() + 3600 * 1000 * 24).toISOString().split('T')[0],
          tmpMax = new Date(sessionFilters['filters'][0][1][1]), strMax = new Date(tmpMax.getTime() + 3600 * 1000 * 24).toISOString().split('T')[0];

          sessionFilters['filters'][0][1] = [strMin, strMax]
        }

        //console.log({sessionFilters})

        this.setSessionFilters(sessionFilters)
      },
      resetFilters() {
        const dateRange = [store.mmDateRange[0], store.mmDateRange[1]],
        deviceType = store.deviceSelected,
        productType = store.productSelected, productSubtype = store.subProductSelected,
        billingEntity = store.companySelected,
        siteName = store.siteSelected;

        // Data filters (VueJS)
        this.filters = []
        this.filters.push(['date', dateRange])
        this.filters.push(['product_type', productType])
        this.filters.push(['product_subtype', productSubtype])
        this.filters.push(['billing_entity', billingEntity])
        this.filters.push(['site_name', siteName])
        this.filters.push(['device_type', deviceType])
      },
      resetMundialData() {
        store.mmText = 'Last 30 Days'
        store.mmDateRange = store.ogMmDateRange
        store.companySelected = 'all'
        store.siteSelected = ['all']
        store.deviceSelected = 'all'
        store.productSelected = ['all']
        store.subProductSelected = ['all']
        store.aggregateSelected = 'total'

        this.setSummaryData()
      },
      async setSessionFilters(params) {
        //console.log({params,store})
        await store.resetSessionFilters(params)
      },
    },
    async mounted () {
      //await store.initMundial()

      // Add router to push to Login page upon expired session
      // Consider moving router to router.js (currently main.js)
      if (store.mmData) {
        //store.session_router = this.$router

        // Adjust sessionRange dates only when outside mmRange
        //store.mmData.adjust_custom[dashName] = false
      }

      // Filter variables
      const lastThirty = mmDate.GetLastThirty(),
      sDate = lastThirty['startDay'].toISOString().split('T')[0],
      eDate = lastThirty['endDay'].toISOString().split('T')[0],
      sessionFilters = store.mmData.sessionFilters;

      if (sessionFilters) {
        this.filters = sessionFilters.filters
        store.mmText = sessionFilters.mmText
        store.aggregateSelected = sessionFilters.aggregateSelected

        const filters = this.filters, filterLen = filters.length;
        for (let f = 0; f < filterLen; f++) {
          const tmpFilter = filters[f],
          tmpKey = tmpFilter[0],
          storeKey = storeNameDict[tmpKey];
          let tmpVal = tmpFilter[1];

          if (tmpKey === 'date' && store.mmText === 'Custom Range' && store.customQuery) {
            const tmpMin = new Date(tmpVal[0]), tmpMax = new Date(tmpVal[1]),
            adjMin = new Date(tmpMin.getTime() + 3600 * 1000 * 24),
            adjMax = new Date(tmpMax.getTime() + 3600 * 1000 * 24);

            //console.log({tmpMin,tmpMax,tmpVal,adjMin,adjMax})
            tmpVal = [adjMin, adjMax]
          }

          store[storeKey] = tmpVal
        }
      } else {
        store.mmDateRange = [sDate, eDate]
      }

      store.ogMmDateRange = [sDate, eDate]
      //this.setSummaryData()

      //console.log({store})

      //this.resetMundialData()
    },
    computed: {
      mmData () {
        return store.mmData
      },
      startDate () {
        return store.mmDateRange.length ? this.store.mmDateRange[0] : ''
      },
      endDate () {
        return store.mmDateRange.length ? this.store.mmDateRange[1] : ''
      },
      matchedAdReqChartData () {
        return this.matchedAdReqChart.data
      },
      impChartData () {
        return this.impChart.data
      },
      revenueChartData () {
        return this.revenueChart.data
      },
      siteOptions () {
        const companySelected = store.companySelected === 'all' ? false : store.mmData.be_options.filter(obj => obj.label.toLowerCase() === store.companySelected)[0],
        be_id = companySelected ? companySelected.value : false,
        be_sn_set = be_id ? new Set(store.mmData.be_sn_dict.filter(obj => obj.be_id === be_id)[0].sn_id.split(',')) : false;

        //console.log({companySelected, be_id, be_sn_set})

        return be_sn_set ? store.mmData.site_options.filter(obj => be_sn_set.has(obj.value.toString())) : store.mmData.site_options
      },
      subProductOptionsOG () {
        const productSelected = store.productSelected === 'all' ? false : store.mmData.product_options.filter(obj => obj.label.toLowerCase() === store.productSelected)[0],
        product_id = productSelected ? productSelected.value : false,
        subproduct_list = store.mmData.product_subproduct_dict.filter(obj => obj.product_type_id === product_id),
        product_subproduct_set = product_id && subproduct_list.length ? new Set(subproduct_list[0].product_subtype_id.split(',')) : false;

        return product_subproduct_set ? store.mmData.subproduct_options.filter(obj => product_subproduct_set.has(obj.value.toString())) :
          product_id && !subproduct_list.length ? [] : store.mmData.subproduct_options
      },
      subProductOptions () {
        const productSet = new Set(store.productSelected), allFlag = productSet.has('all'),
        productSelected = allFlag ? false : store.mmData.product_options.filter(obj => productSet.has(obj.label.toLowerCase())),
        productIdSet = productSelected ? new Set(productSelected.map(prod => prod.value)) : false,
        subproduct_list = productIdSet ? store.mmData.product_subproduct_dict.filter(obj => productIdSet.has(obj.product_type_id)) : [],
        product_subproduct_set = productIdSet && subproduct_list.length ? new Set(subproduct_list.flatMap(obj => obj.product_subtype_id.split(','))) : false;

        //console.log({productSet,allFlag,productSelected,productIdSet,subproduct_list})

        return product_subproduct_set ? store.mmData.subproduct_options.filter(obj => product_subproduct_set.has(obj.value.toString())) :
          productIdSet && !subproduct_list.length ? [] : store.mmData.subproduct_options
      },
    },
  }

</script>
<style>
.floatRight {
    right: 0;
    text-align: right;
    padding-left: 15px;
    padding-right: 15px;
    left: auto !important;
    min-width: 100% !important;
}
.mundialBorders {
    background-color: transparent !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
.mundialHeader {
    border-top: none;
    margin-top: -1em;
    margin-bottom: 3em;
}
.mundial-default.el-select .el-input input {
    background-color: #fff;
    border-color: #ddd!important;
    border-width: 2px;
    border-radius: 12px;
    color: #66615b;
}
.el-date-editor .el-range__close-icon {
    display: none;
}
.select-default.el-select .el-input .el-input__icon {
    border-radius: 20px;
    height: 36px;
    width: 30px;
    line-height: 36px;
}
.select-default.el-select-dropdown__item.selected, .select-default.el-select-dropdown__item.selected.hover {
    background-color: #ddd;
    color: #66615b;
}
.el-range-editor.is-active, .el-range-editor.is-active:hover {
    border-color: #9a9a9a;
}
.daterange-mundial {
    border-radius: 12px !important;
    border: 2px solid #ddd !important;
}
.mundial-vcenter {
    display: table;
}
.mundial-vcenter button {
    margin-top: 1em;
}
.mundial-vcenter > .mundial-a {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}
.mundial-vcenter > .btn-mundial-reset {
    float: right;
}
.btn-mundial-reset:hover, .btn-mundial-reset:focus {
    background-color: #66615b !important;
}
.btn-mundial-avails:hover, .btn-mundial-avails:focus {
    background-color: #51bcda !important;
}
.systemStatus {
    display: block;
    text-align: center !important;
    background-color: #EDD14C !important;
    padding: 0.25em;
    font-weight: bolder;
    margin-top: -30px;
}
.el-select__tags-text {
    color: white;
}
</style>
